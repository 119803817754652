import axios from "axios"
import _ from "underscore"
import moment from "moment"
import * as Sentry from "@sentry/react"

import api from "./api"
import { authHeader } from "./auth-helper"
import { API_URL } from "../utils/constants"
import sleep from "../utils/sleep"

export const pingAndWait = async (leadId) => {
  console.log("Starting Ping", leadId)
  try {
    let lead = await fetchLead(leadId)
    console.log("Fetched Lead:", lead)
    if (lead && lead.data.actions.ping) {
      console.log("Lead Can Be Pinged:", lead)
      lead = await pingLead(leadId)
    } else {
      // No need to ping if we already have a result
      if (lead.data.status !== "pending" && lead.data.status !== "processing") {
        return { success: true, lead }
      }
      console.log("Lead Can Not Be Pinged:", lead)
      return { success: false, error: "Can not ping lead." }
    }

    while (
      lead.data.status === "pending" ||
      lead.data.status === "processing"
    ) {
      console.log("Polling Lead Ping:", lead)
      await sleep(3000)
      lead = await fetchLead(leadId)
    }

    const { id, status } = lead.data
    window.parent &&
      window.parent.postMessage({ type: "lead", id, status }, "*")

    return { success: true, lead }
  } catch (err) {
    console.error("Error Pinging Lead:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err }
  }
}

export const createLead = async (lead) => {
  console.log("Creating Lead")
  try {
    const response = await axios.post(`${API_URL}/v1/leads/`, lead)
    const { data } = response
    const { id, status } = data
    window.parent &&
      window.parent.postMessage({ type: "lead", id, status }, "*")
    return { success: true, data }
  } catch (err) {
    console.error("Error Creating Lead:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const updateLead = async (leadId, lead) => {
  console.log("Updating Lead")
  try {
    const response = await axios.patch(`${API_URL}/v1/leads/${leadId}/`, lead, {
      headers: authHeader(),
    })
    const { data } = response
    const { id, status } = data
    window.parent &&
      window.parent.postMessage({ type: "lead", id, status }, "*")
    return { success: true, data }
  } catch (err) {
    console.error("Error Updating Lead:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const copyLead = async (leadId, lead) => {
  console.log("Copying Lead")
  try {
    const response = await axios.post(
      `${API_URL}/v1/leads/${leadId}/copy/`,
      lead,
      {
        headers: authHeader(),
      }
    )
    const { data } = response
    const { id, status } = data
    window.parent &&
      window.parent.postMessage({ type: "lead", id, status }, "*")
    return { success: true, data }
  } catch (err) {
    console.error("Error Copying Lead:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const acceptOffer = async (offerId) => {
  console.log("Accepting Offer", offerId)
  let payload = {
    address: { status: "rent" },
    bank_account: {
      account_number: 1231213,
      account_type: "checking",
      routing_number: 111000025,
    },
    debit_card: {
      cvv: 123,
      expiry_month: 10,
      expiry_year: 2024,
      number: "4111111111111111",
      zip: 10003,
    },
    income: {
      last_pay_date: "2022-04-11",
      next_pay_date: "2022-04-18",
      payroll_frequency: "weekly",
      net_monthly_income: 2000.0,
    },
  }
  payload = {}
  console.log("accept offer?", offerId)
  try {
    const response = await api.post(
      `${API_URL}/v1/offers/${offerId}/apply/`,
      JSON.stringify(payload)
    )
    console.log("API Response", response)
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Error Accepting Offer:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const pingLead = async (leadId) => {
  console.log("Pinging Lead:", leadId)
  try {
    const response = await api.post(`${API_URL}/v1/leads/${leadId}/ping/`, {})
    if (response.status >= 200 && response.status < 300) {
      console.log("Ping Successful:", response.data)
    } else if (response.status === 403) {
      console.log("Lead Already Pinged:", response.data)
    } else {
      console.log("Unknown Ping Error:", response.data)
    }
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Ping Error:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const reapplyLead = async (leadId) => {
  console.log("Reapplying Lead:", leadId)
  try {
    const response = await api.post(
      `${API_URL}/v1/leads/${leadId}/reapply/`,
      {}
    )
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Reapply Error:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const validateLead = async (leadId) => {
  console.log("Validating Lead:", leadId)
  try {
    const response = await api.post(
      `${API_URL}/v1/leads/${leadId}/validate/`,
      {}
    )
    if (response.status >= 200 && response.status < 300) {
      console.log("Validation Success:", response.data)
    } else {
      console.log("Validation Failed:", response.data)
    }
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Validation Error:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const fetchLead = async (leadId) => {
  console.log("Fetching Lead:", leadId)
  try {
    const response = await api.get(`${API_URL}/v1/leads/${leadId}/`)
    const { data } = response
    const { id, status } = data
    window.parent &&
      window.parent.postMessage({ type: "lead", id, status }, "*")
    console.log("fetch status", status)
    return { success: true, data }
  } catch (err) {
    console.error("Fetching Lead Error:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const getLeads = async () => {
  console.log("Fetching Leads")
  try {
    const response = await api.get(`${API_URL}/v1/leads/`)
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Error Fetching Leads:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const getUserLeads = async (username) => {
  console.log("Fetching User Leads:", username)
  try {
    const response = await axios.get(`${API_URL}/v1/users/${username}/leads/`, {
      headers: authHeader(),
    })
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Error Fetching User Leads:", err, err.response.data)
    Sentry.captureException(err)
    return { success: false, error: err.message }
  }
}

/**
 * This function prepares a lead by formatting input values for API.
 *
 * @param {object} values the inputs values of a lead before creation
 * @returns {object} the values formatted for lead creation
 */
export const prepareLead = async (values) => {
  const leadObj = JSON.parse(JSON.stringify(values))

  if (_.contains(_.keys(leadObj), "personal")) {
    _.keys(leadObj.personal).forEach((key) => {
      if (_.contains(["ssn", "phone"], key)) {
        leadObj.personal[key] = leadObj.personal[key].replace(/\D/g, "")
      }
      if (_.contains(["dob"], key)) {
        leadObj.personal[key] = moment(leadObj.personal[key]).format(
          "YYYY-MM-DD"
        )
      }
    })
  }

  if (_.contains(_.keys(leadObj), "income")) {
    console.log("does it got income")
    if (_.contains(_.keys(leadObj.income), "annual_income")) {
      if (typeof leadObj.income.annual_income !== "number") {
        leadObj.income.annual_income = parseInt(
          leadObj.income.annual_income.replace(/\D/g, "")
        )
      }
    }
    if (_.contains(_.keys(leadObj.income), "monthly_household_expense")) {
      if (typeof leadObj.income.monthly_household_expense !== "number") {
        leadObj.income.monthly_household_expense = parseInt(
          leadObj.income.monthly_household_expense.replace(/\D/g, "")
        )
      }
    }
    if (_.contains(_.keys(leadObj.income), "net_monthly_income")) {
      if (typeof leadObj.income.net_monthly_income !== "number") {
        leadObj.income.net_monthly_income = parseInt(
          leadObj.income.net_monthly_income.replace(/\D/g, "")
        )
      }
    }
  }

  /*
    Old Code for Address a Date
    if (_.contains(_.keys(leadObj), 'address')) {
        if (_.contains(_.keys(leadObj['address']), 'address_length')) {
            console.log("so lets see what this object is", leadObj['address']['address_length'], _.isNumber(leadObj['address']['address_length']));
            if (!_.isNumber(leadObj['address']['address_length'])) {
                const date = moment(leadObj['address']['address_length'])
                const now = moment();
                leadObj['address']['address_length'] = now.diff(date, 'months');
            }
        }
    }
    */

  return leadObj
}
